export const urlRoutes = {
  /**************************/
  auth: {
    url: '/autenticacion/*',

    login: {
      url: '/acceso',
      urlTo: '/autenticacion/acceso'
    },
    register: {
      url: '/registrar',
      urlTo: '/autenticacion/registrar'
    },
    registerValidate: {
      url: '/registrar/validar',
      urlTo: '/autenticacion/registrar/validar'
    },
    registerStudent: {
      url: '/registrar/estudiante',
      urlTo: '/autenticacion/registrar/estudiante'
    },
    forgotPassword: {
      url: '/recuperar',
      urlTo: '/autenticacion/recuperar'
    },
    updatePassword: {
      url: '/actualizar-contrasena',
      urlTo: '/autenticacion/actualizar-contrasena'
    },
    resendEmail: {
      url: '/reenviar-correo',
      urlTo: '/autenticacion/reenviar-correo'
    }
  },

  student: {
    home: {
      url: '/'
    }
  },

  applicant: {
    url: '/*',

    admissions: {
      announcement: {
        opening: {
          url: '/admisiones/convocatoria/apertura',
          urlTo: '/admisiones/convocatoria/apertura'
        }
      }
    },
    home: {
      url: '/inicio',
      urlTo: '/inicio'
    },
    newAnnouncement: {
      url: '/nueva-convocatoria',
      urlTo: '/nueva-convocatoria'
    },
    regulation: {
      url: '/reglamentos',
      urlTo: '/reglamentos'
    },
    config: {
      myInformation: {
        url: '/configuracion/mi-informacion',
        urlTo: '/configuracion/mi-informacion'
      },
      letter: {
        url: '/configuracion/carta-constancia',
        urlTo: '/configuracion/carta-constancia'
      },
      certificado: {
        url: '/configuracion/certificado',
        urlTo: '/configuracion/certificado'
      },
      report: {
        url: '/configuracion/reportes',
        urlTo: '/configuracion/reportes'
      }
    },
    profile: {
      url: '/',
      urlTo: '/'
    },
    request: {
      url: '/solicitud',
      urlTo: '/solicitud'
    },
    movement: {
      transfer: {
        url: '/movimiento/transferencia',
        create: '/movimiento/transferencia/crear'
      },
      retire: {
        url: '/movimiento/retiro',
        create: '/movimiento/retiro/crear'
      },

    }
  },

  /***************************/
  security: {
    roles: {
      urlTo: '/seguridad/roles'
    },
    modules: {
      add: {
        urlTo: '/seguridad/modules/crear'
      },
      edit: {
        urlTo: '/seguridad/modules/editar/:id'
      },
      urlTo: '/seguridad/modules'
    }
  },

  management: {
    url: '/*',

    dashboard: {
      url: '/',
      urlTo: '/'
    },

    profile: {
      url: 'perfil',
      urlTo: 'perfil'
    },

    admissions: {
      announcement: {
        qualifications: {
          url: '/admisiones/convocatoria/calificaciones',
          urlTo: '/admisiones/convocatoria/calificaciones'
        },
        validation: {
          list: {
            url: '/admisiones/convocatoria/validacion',
            urlTo: '/admisiones/convocatoria/validacion'
          }
        },
        validationTeacher: {
          list: {
            url: '/admisiones/convocatoria/validacion-docente',
            urlTo: '/admisiones/convocatoria/validacion-docente'
          }
        },
        opening: {
          url: '/admisiones/convocatoria/apertura',
          urlTo: '/admisiones/convocatoria/apertura'
        },
        status: {
          url: '/admisiones/convocatoria/status',
          urlTo: '/admisiones/convocatoria/status'
        },
        statusApplicant: {
          url: '/admisiones/convocatoria/status-aplicante',
          urlTo: '/admisiones/convocatoria/status-aplicante'
        },
        level: {
          list: {
            url: '/admisiones/convocatoria/nivel',
            urlTo: '/admisiones/convocatoria/nivel'
          }
        },
        technical: {
          list: {
            url: '/admisiones/convocatoria/tecnicos',
            urlTo: '/admisiones/convocatoria/tecnicos'
          },
          create: {
            url: '/admisiones/convocatoria/tecnicos/crear',
            urlTo: '/admisiones/convocatoria/tecnicos/crear'
          }
        },
        teacher: {
          list: {
            url: '/admisiones/convocatoria/profesores',
            urlTo: '/admisiones/convocatoria/profesores'
          },
          create: {
            url: '/admisiones/convocatoria/profesores/crear',
            urlTo: '/admisiones/convocatoria/profesores/crear'
          }
        },
        manager: {
          list: {
            url: '/admisiones/convocatoria/asignacion-profesor-encargado',
            urlTo: '/admisiones/convocatoria/asignacion-profesor-encargado'
          },
          create: {
            url: '/admisiones/convocatoria/asignacion-profesor-encargado/crear',
            urlTo:
              '/admisiones/convocatoria/asignacion-profesor-encargado/crear'
          }
        },
        passListLevel: {
          url: '/admisiones/convocatoria/pase-de-lista-nivel',
          urlTo: '/admisiones/convocatoria/pase-de-lista-nivel'
        },
        changeStatus: {
          url: '/admisiones/convocatoria/cambio-estado',
          urlTo: '/admisiones/convocatoria/cambio-estado'
        },
        students: {
          url: '/admisiones/convocatoria/validacion',
          urlTo: '/admisiones/convocatoria/validacion',
          detail: {
            url: '/admisiones/convocatoria/solicitud',
            urlTo: '/admisiones/convocatoria/solicitud'
          },
          add: {
            url: '/admisiones/convocatoria/estudiantes',
            urlTo: '/admisiones/convocatoria/estudiantes',

            create: {
              url: '/admisiones/convocatoria/estudiantes/crear',
              urlTo: '/admisiones/convocatoria/estudiantes/crear'
            },

            validate: {
              url: '/admisiones/convocatoria/estudiantes/validar',
              urlTo: '/admisiones/convocatoria/estudiantes/validar'
            },
            form: {
              url: '/admisiones/convocatoria/estudiantes/formulario',
              urlTo: '/admisiones/convocatoria/estudiantes/formulario'
            }
          }
        }
      },
      setting: {
        language: {
          list: {
            url: '/configuracion/idioma',
            urlTo: '/configuracion/idioma'
          },
          create: {
            url: '/configuracion/idioma/crear',
            urlTo: '/configuracion/idioma/crear'
          }
        },
        transferReason: {
          list: {
            url: '/configuracion/transferencia',
            urlTo: '/configuracion/transferencia'
          },
          create: {
            url: '/configuracion/transferencia/crear',
            urlTo: '/configuracion/transferencia/crear'
          }
        },
        announcement: {
          list: {
            url: '/configuracion/convocatoria',
            urlTo: '/configuracion/convocatoria'
          },
          create: {
            url: '/configuracion/convocatoria/crear',
            urlTo: '/configuracion/convocatoria/crear'
          }
        },
        seat: {
          url: '/configuracion/centros',
          urlTo: '/configuracion/centros/0',
          institution: {
            list: {
              url: '/configuracion/centros/institucion',
              urlTo: '/configuracion/centros/institucion'
            },
            create: {
              url: '/configuracion/centros/institucion/crear',
              urlTo: '/configuracion/centros/institucion/crear'
            }
          },
          enclosure: {
            list: {
              url: '/configuracion/centros/recinto',
              urlTo: '/configuracion/centros/recinto'
            },
            create: {
              url: '/configuracion/centros/recinto/crear',
              urlTo: '/configuracion/centros/recinto/crear'
            }
          }
        },
        evaluation: {
          list: {
            url: '/configuracion/evaluacion',
            urlTo: '/configuracion/evaluacion'
          },
          create: {
            url: '/configuracion/evaluacion/crear',
            urlTo: '/configuracion/evaluacion/crear'
          },
          bulkEdit: {
            url: '/configuracion/evaluacion/edicion-masiva',
            urlTo: '/configuracion/evaluacion/edicion-masiva'
          }
        },
        levelAnnoucement: {
          list: {
            url: '/configuracion/nivel-convocatoria',
            urlTo: '/configuracion/nivel-convocatoria'
          },
          create: {
            url: '/configuracion/nivel-convocatoria/crear-convocatoria',
            urlTo: '/configuracion/nivel-convocatoria/crear-convocatoria'
          },
          bulkEdit: {
            url: '/configuracion/nivel-convocatoria/edicion-masiva',
            urlTo: '/configuracion/nivel-convocatoria/edicion-masiva'
          }
        },
        level: {
          list: {
            url: '/configuracion/nivel',
            urlTo: '/configuracion/nivel'
          },
          create: {
            url: '/configuracion/nivel/crear',
            urlTo: '/configuracion/nivel/crear'
          },
          edit: {
            url: 'configuracion/nivel/editar/:id',
            urlTo: 'configuracion/nivel/editar/:id'
          }
        },
        modality: {
          list: {
            url: '/configuracion/modalidad',
            urlTo: '/configuracion/modalidad'
          },
          create: {
            url: '/configuracion/modalidad/crear',
            urlTo: '/configuracion/modalidad/crear'
          }
        },
        quota: {
          list: {
            url: '/configuracion/cupos',
            urlTo: '/configuracion/cupos'
          },
          create: {
            url: '/configuracion/cupos/crear',
            urlTo: '/configuracion/cupos/crear'
          }
        }
      }
    },
    academic: {
      training: {
        register: {
          list: {
            url: '/academico/capacitacion/registro',
            urlTo: '/academico/capacitacion/registro'
          },
          create: {
            url: '/academico/capacitacion/registro/crear',
            urlTo: '/academico/capacitacion/registro/crear'
          }
        },
        event: {
          list: {
            url: '/academico/capacitacion/evento',
            urlTo: '/academico/capacitacion/evento'
          },
          create: {
            url: '/academico/capacitacion/evento/crear',
            urlTo: '/academico/capacitacion/evento/crear'
          }
        }
      },
      seat: {
        teacherPassList: {
          url: '/academico/centros/pase-lista-profesor',
          urlTo: '/academico/centros/pase-lista-profesor'
        },
        tardiesExcuses: {
          url: '/academico/centros/tardanzas-excusas',
          urlTo: '/academico/centros/tardanzas-excusas'
        },
        registrationTeacherAttendanceControl: {
          url: '/academico/centros/registro-control-asistencia-profesor',
          urlTo: '/academico/centros/registro-control-asistencia-profesor'
        },
        registerQualifications: {
          url: '/academico/centros/registro-de-calificaciones',
          urlTo: '/academico/centros/registro-de-calificaciones'
        },
        qualifications: {
          url: '/academico/centros/calificaciones',
          urlTo: '/academico/centros/calificaciones'
        },
        classroom: {
          list: {
            url: '/academico/centros/aula',
            urlTo: '/academico/centros/aula'
          },
          create: {
            url: '/academico/centros/aula/crear',
            urlTo: '/academico/centros/aula/crear'
          }
        },
        studentClassroom: {
          list: {
            url: '/academico/centros/asignacion-estudiante-aula',
            urlTo: '/academico/centros/asignacion-estudiante-aula'
          },
          create: {
            url: '/academico/centros/asignacion-estudiante-aula/crear',
            urlTo: '/academico/centros/asignacion-estudiante-aula/crear'
          }
        },
        teacherClassroom: {
          list: {
            url: '/academico/centros/asignacion-profesor-aula',
            urlTo: '/academico/centros/asignacion-profesor-aula'
          },
          create: {
            url: '/academico/centros/asignacion-profesor-aula/crear',
            urlTo: '/academico/centros/asignacion-profesor-aula/crear'
          }
        },
        student: {
          url: '/academico/centros/estudiantes',
          urlTo: '/academico/centros/estudiantes'
        },
        examen: {
          list: {
            url: '/academico/centros/examen',
            urlTo: '/academico/centros/examen'
          },
          exam: {
            edit: {
              url: '/academico/centros/examen/editar',
              urlTo: '/academico/centros/examen/editar'
            },
            create: {
              url: '/academico/centros/examen/crear-examen/crear',
              urlTo: '/academico/centros/examen/crear-examen/crear'
            },
            list: {
              url: '/academico/centros/examen/crear-examen',
              urlTo: '/academico/centros/examen/crear-examen'
            },
            assignmentExam: {
              list: {
                url: '/academico/centros/examen/asignacion',
                urlTo: '/academico/centros/examen/asignacion'
              },
              create: {
                url: '/academico/centros/examen/asignacion/crear-asignacion-examen',
                urlTo: '/academico/centros/examen/asignacion/crear-asignacion-examen'
              },
              edit: {
                url: '/academico/centros/examen/asignacion/editar-asignacion-examen',
                urlTo: '/academico/centros/examen/asignacion/editar-asignacion-examen'
              }
            },
            habilitationExam: {
              list: {
                url: '/academico/centros/examen/habilitacion',
                urlTo: '/academico/centros/examen/habilitacion'
              },
              create: {
                url: '/academico/centros/examen/asignacion/crear-habilitacion-examen',
                urlTo: '/academico/centros/examen/asignacion/crear-habilitacion-examen/:id'
              },
              edit: {
                url: '/academico/centros/examen/asignacion/editar-habilitacion-examen',
                urlTo: '/academico/centros/examen/asignacion/editar-habilitacion-examen'
              }
            }
          },
          narrative: {
            create: {
              url: '/academico/centros/examen/narrativa/crear',
              urlTo: '/academico/centros/examen/narrativa/crear'
            },
            list: {
              url: '/academico/centros/examen/narrativa',
              urlTo: '/academico/centros/examen/narrativa'
            }
          },
          examenCategory: {
            create: {
              url: '/academico/centros/examen/categoria/crear',
              urlTo: '/academico/centros/examen/categoria/crear'
            },
            list: {
              url: '/academico/centros/examen/categoria',
              urlTo: '/academico/centros/examen/categoria'
            }
          },
          question: {
            create: {
              url: '/academico/centros/examen/pregunta/crear',
              urlTo: '/academico/centros/examen/pregunta/crear'
            },
            list: {
              url: '/academico/centros/examen/pregunta',
              urlTo: '/academico/centros/examen/pregunta'
            }
          },

        },
        bankExams:{
          list: {
            url: '/academico/centros/banco-examenes',
            urlTo: '/academico/centros/banco-examenes'
          },
          edit: {
            url:'/academico/centros/banco-examenes/editar',
            urlTo: '/academico/centros/banco-examenes/editar'
          },
          view: {
            url:'/academico/centros/banco-examenes/visualizar',
            urlTo: '/academico/centros/banco-examenes/visualizar'
          }
        },
        gradeCalculationRequest: {
          list: {
            url: '/academico/centros/solicitud-calculo-de-calificaciones',
            urlTo: '/academico/centros/solicitud-calculo-de-calificaciones'
          },
          create: {
            url: '/academico/centros/solicitud-calculo-de-calificaciones/crear',
            urlTo: '/academico/centros/solicitud-calculo-de-calificaciones/crear'
          },
        },
        startLevelRequest: {
          list: {
            url: '/academico/centros/solicitud-inicio-nivel',
            urlTo: '/academico/centros/solicitud-inicio-nivel'
          },
          create: {
            url: '/academico/centros/solicitud-inicio-nivel/crear',
            urlTo: '/academico/centros/solicitud-inicio-nivel/crear'
          },
        },
        dischargeFixedAssets: {
          list: {
            url: '/academico/centros/solicitud-activos-fijos',
            urlTo: '/academico/centros/solicitud-activos-fijos'
          },
          create: {
            url: '/academico/centros/solicitud-activos-fijos/crear',
            urlTo: '/academico/centros/solicitud-activos-fijos/crear'
          },
        },
        physicalPlantMaintenance: {
          list: {
            url: '/academico/centros/mantenimiento-planta-fisica',
            urlTo: '/academico/centros/mantenimiento-planta-fisica'
          },
          create: {
            url: '/academico/centros/mantenimiento-planta-fisica/crear',
            urlTo: '/academico/centros/mantenimiento-planta-fisica/crear'
          }
        },
        laboratoryMaintenanceRequest: {
          list: {
            url: '/academico/centros/mantenimiento-laboratorio-centro',
            urlTo: '/academico/centros/mantenimiento-laboratorio-centro'
          },
          create: {
            url: '/academico/centros/mantenimiento-laboratorio-centro/crear',
            urlTo: '/academico/centros/mantenimiento-laboratorio-centro/crear'
          }
        },
        technicalSupport: {
          list: {
            url: '/academico/centros/soporte-tecnico',
            urlTo: '/academico/centros/soporte-tecnico'
          },
          create: {
            url: '/academico/centros/soporte-tecnico/crear',
            urlTo: '/academico/centros/soporte-tecnico/crear'
          }
        },
        assigningBooksStudents: {
          list: {
            url: '/academico/centros/asignacion-libros-estudiantes',
            urlTo: '/academico/centros/asignacion-libros-estudiantes'
          },
          create: {
            url: '/academico/centros/asignacion-libros-estudiantes/crear',
            urlTo: '/academico/centros/asignacion-libros-estudiantes/crear'
          }
        },
      },
      evaluation: {
        teachingCreation: {
          list: {
            url: '/academico/evaluaciones/apertura-docente',
            urlTo: '/academico/evaluaciones/apertura-docente'
          },
          create: {
            url: '/academico/evaluaciones/apertura-docente/crear',
            urlTo: '/academico/evaluaciones/apertura-docente/crear'
          }
        },
        teachingStatus: {
          list: {
            url: '/academico/evaluaciones/estatus-apertura',
            urlTo: '/academico/evaluaciones/estatus-apertura'
          }
        }
      },
      supervision: {
        supervisor: {
          list: {
            url: '/academico/supervision/supervisor',
            urlTo: '/academico/supervision/supervisor'
          },
          create: {
            url: '/academico/supervision/supervisor/crear',
            urlTo: '/academico/supervision/supervisor/crear'
          },
          edit: {
            url: '/academico/supervision/supervisor/editar',
            urlTo: '/academico/supervision/supervisor/editar'
          }
        },
        question: {
          list: {
            url: '/academico/supervision/preguntas',
            urlTo: '/academico/supervision/preguntas'
          },
          create: {
            url: '/academico/supervision/preguntas/crear',
            urlTo: '/academico/supervision/preguntas/crear'
          }
        },
        questionBank: {
          list: {
            url: '/academico/supervision/banco-preguntas',
            urlTo: '/academico/supervision/banco-preguntas'
          },
          create: {
            url: '/academico/supervision/banco-preguntas/crear',
            urlTo: '/academico/supervision/banco-preguntas/crear'
          }
        },
        registerVisit: {
          list: {
            url: '/academico/supervision/registro-visitas',
            urlTo: '/academico/supervision/registro-visitas'
          },
          create: {
            url: '/academico/supervision/registro-visitas/crear',
            urlTo: '/academico/supervision/registro-visitas/crear'
          },
          edit: {
            url: '/academico/supervision/registro-visitas/editar',
            urlTo: '/academico/supervision/registro-visitas/editar'
          }
        }
      },
      intitutions: {
        movements: {
          url: '/admisiones/convocatoria/movimientos',
          urlTo: '/admisiones/convocatoria/movimientos',
          newTransferRequest: {
            url: '/admisiones/convocatoria/transferRequest',
            urlTo: '/admisiones/convocatoria/transferRequest'
          },
          editTransferRequest: {
            url: '/academico/centros/movimientos/editar-solicitud-transferencia',
            urlTo:
              '/academico/centros/movimientos/editar-solicitud-transferencia',
          },
          editWithdrawal: {
            url: 'admisiones/convocatoria/movimientos/retiro/editar-solicitud-retiro',
            urlTo: 'admisiones/convocatoria/movimientos/retiro/editar-solicitud-retiro',
          },
          manualTransfer: '/academico/centros/movimientos/transferencia-manual',
        },
        asignations: {
          coordinator: {
            list: {
              url: '/academico/centros/asignacion-coordinador',
              urlTo: '/academico/centros/asignacion-coordinador'
            },
            create: {
              url: '/academico/centros/asignacion-coordinador/crear',
              urlTo: '/academico/centros/asignacion-coordinador/crear'
            }
          }
        },
        graduate: {
          url: '/admisiones/convocatoria/egresados',
          urlTo: '/admisiones/convocatoria/egresados'
        }
      }
    },
    administrative: {
      registrationControlBooks: {
        store: {
          list: {
            url: '/administrativo/registro-control-libros/almacen',
            urlTo: '/administrativo/registro-control-libros/almacen'
          },
          create: {
            url: '/administrativo/registro-control-libros/almacen/crear',
            urlTo: '/administrativo/registro-control-libros/almacen/crear'
          }
        },
        booksInStore: {
          list: {
            url: '/administrativo/registro-control-libros/almacen/consulta-almacen',
            urlTo:
              '/administrativo/registro-control-libros/almacen/consulta-almacen'
          }
        },
        addBookToStore: {
          list: {
            url: '/administrativo/registro-control-libros/agregar-libros-almacen',
            urlTo:
              '/administrativo/registro-control-libros/agregar-libros-almacen'
          },
          create: {
            url: '/administrativo/registro-control-libros/agregar-libros-almacen/crear',
            urlTo:
              '/administrativo/registro-control-libros/agregar-libros-almacen/crear'
          }
        },
        requestBook: {
          list: {
            url: '/administrativo/registro-control-libros/solicitud-libros',
            urlTo: '/administrativo/registro-control-libros/solicitud-libros'
          },
          create: {
            url: '/administrativo/registro-control-libros/solicitud-libros-crear',
            urlTo:
              '/administrativo/registro-control-libros/solicitud-libros-crear'
          }
        }
      },
      correspondence: {
        url: '/administrativo/correspondencia',
        urlTo: '/administrativo/correspondencia',
        sent: {
          create: {
            url: '/administrativo/correspondencia/enviada/crear',
            urlTo: '/administrativo/correspondencia/enviada/crear'
          },
          edit: {
            url: '/administrativo/correspondencia/enviada',
            urlTo: '/administrativo/correspondencia/enviada'
          }
        },
        received: {
          create: {
            url: '/administrativo/correspondencia/recibida/crear',
            urlTo: '/administrativo/correspondencia/recibida/crear'
          },
          edit: {
            url: '/administrativo/correspondencia/recibida',
            urlTo: '/administrativo/correspondencia/recibida'
          }
        }
      },
      employee: {
        register: {
          url: '/administrativo/empleados/registro',
          urlTo: '/administrativo/empleados/registro',
          create: {
            url: '/administrativo/empleados/registro/crear',
            urlTo: '/administrativo/empleados/registro/crear'
          },
          edit: {
            url: '/administrativo/empleados/registro/editar',
            urlTo: '/administrativo/empleados/registro/editar'
          }
        },
        resignation: {
          url: '/administrativo/empleados/renuncia',
          urlTo: '/administrativo/empleados/renuncia',
          create: {
            url: '/administrativo/empleados/renuncia-crear',
            urlTo: '/administrativo/empleados/renuncia-crear'
          },
          edit: {
            url: '/administrativo/empleados/renuncia/editar',
            urlTo: '/administrativo/empleados/renuncia/editar'
          }
        },
        transfer: {
          url: '/administrativo/empleados/traslado',
          urlTo: '/administrativo/empleados/traslado',
          create: {
            url: '/administrativo/empleados/traslado-crear',
            urlTo: '/administrativo/empleados/traslado-crear'
          },
          edit: {
            url: '/administrativo/empleados/traslado/editar',
            urlTo: '/administrativo/empleados/traslado/editar'
          }
        }
      },
      fuel: {
        url: '/administrativo/combustible',
        urlTo: '/administrativo/combustible',
        create: {
          url: '/administrativo/combustible/crear',
          urlTo: '/administrativo/combustible/crear'
        },
        edit: {
          url: '/administrativo/combustible/editar',
          urlTo: '/administrativo/combustible/editar'
        }
      },
      travel: {
        url: '/administrativo/viaticos-solicitud',
        urlTo: '/administrativo/viaticos-solicitud',
        create: {
          url: '/administrativo/viaticos-solicitud/crear',
          urlTo: '/administrativo/viaticos-solicitud/crear'
        },
        edit: {
          url: '/administrativo/viaticos-solicitud/editar',
          urlTo: '/administrativo/viaticos-solicitud/editar'
        }
      },
      setting: {
        fixedassets: {
          url: '/administrativo/configuracion/activos-fijos',
          urlTo: '/administrativo/configuracion/activos-fijos',
          active: {
            add: {
              url: '/administrativo/configuracion/activos-fijos/activo/agregar',
              urlTo:
                '/administrativo/configuracion/activos-fijos/activo/agregar'
            }
          },
          brand: {
            add: {
              url: '/administrativo/configuracion/activos-fijos/marca/agregar',
              urlTo: '/administrativo/configuracion/activos-fijos/marca/agregar'
            }
          },
          model: {
            add: {
              url: '/administrativo/configuracion/activos-fijos/modelo/agregar',
              urlTo:
                '/administrativo/configuracion/activos-fijos/modelo/agregar'
            }
          },
          color: {
            add: {
              url: '/administrativo/configuracion/activos-fijos/color/agregar',
              urlTo: '/administrativo/configuracion/activos-fijos/color/agregar'
            }
          },
          location: {
            add: {
              url: '/administrativo/configuracion/activos-fijos/localidad/agregar',
              urlTo: '/administrativo/configuracion/activos-fijos/localidad/agregar'
            }
          },
          typology: {
            add: {
              url: '/administrativo/configuracion/activos-fijos/tipologia/agregar',
              urlTo:
                '/administrativo/configuracion/activos-fijos/tipologia/agregar'
            }
          },
          status: {
            add: {
              url: '/administrativo/configuracion/activos-fijos/estado/agregar',
              urlTo:
                '/administrativo/configuracion/activos-fijos/estado/agregar'
            }
          },
          nature: {
            add: {
              url: '/administrativo/configuracion/activos-fijos/naturaleza/agregar',
              urlTo:
                '/administrativo/configuracion/activos-fijos/naturaleza/agregar'
            }
          }
        },
        fixedAssetsRegistry: {
          url: '/administrativo/configuracion/mantenimiento-activos-fijos',
          urlTo: '/administrativo/configuracion/mantenimiento-activos-fijos',
          add: {
            url: '/administrativo/configuracion/mantenimiento-activos-fijos/agregar',
            urlTo: '/administrativo/configuracion/mantenimiento-activos-fijos/agregar'
          },
        },
        maintenance: {
          url: '/administrativo/configuracion/mantenimiento',
          urlTo: '/administrativo/configuracion/mantenimiento',
          add: {
            url: '/administrativo/configuracion/mantenimiento/agregar',
            urlTo: '/administrativo/configuracion/mantenimiento/agregar'
          }
        },
        correspondence: {
          url: '/administrativo/configuracion/correspondencia',
          urlTo: '/administrativo/configuracion/correspondencia',
          add: {
            url: '/administrativo/configuracion/correspondencia/agregar',
            urlTo: '/administrativo/configuracion/correspondencia/agregar'
          }
        },
        position: {
          url: '/administrativo/configuracion/posicion',
          urlTo: '/administrativo/configuracion/posicion',
          add: {
            url: '/administrativo/configuracion/posicion/agregar',
            urlTo: '/administrativo/configuracion/posicion/agregar'
          }
        },
        careers: {
          list: {
            url: '/administrativo/configuracion/carreras',
            urlTo: '/administrativo/configuracion/carreras'
          },
          create: {
            url: '/administrativo/configuracion/carreras/crear',
            urlTo: '/administrativo/configuracion/carreras/crear'
          }
        },
        universities: {
          list: {
            url: '/administrativo/configuracion/universidades',
            urlTo: '/administrativo/configuracion/universidades'
          },
          create: {
            url: '/administrativo/configuracion/universidades/crear',
            urlTo: '/administrativo/configuracion/universidades/crear'
          }
        },
        bookRecord: {
          url: '/administrativo/configuracion/registro-libros',
          urlTo: '/administrativo/configuracion/registro-libros',
          typology: {
            add: {
              url: '/administrativo/configuracion/registro-libros/tipologia/agregar',
              urlTo:
                '/administrativo/configuracion/registro-libros/tipologia/agregar'
            }
          },
          book: {
            add: {
              url: '/administrativo/configuracion/registro-libros/libro/agregar',
              urlTo:
                '/administrativo/configuracion/registro-libros/libro/agregar'
            }
          }
        },
        perDiemAndTravel: {
          url: '/administrativo/configuracion/viaticos-y-viajes',
          urlTo: '/administrativo/configuracion/viaticos-y-viajes',
          add: {
            url: '/administrativo/configuracion/viaticos-y-viajes/agregar',
            urlTo: '/administrativo/configuracion/viaticos-y-viajes/agregar'
          }
        },
        materialsBooks: {
          url: '/administrativo/configuracion/materiales-y-libros',
          urlTo: '/administrativo/configuracion/materiales-y-libros',
          article: {
            add: {
              url: '/administrativo/configuracion/materiales-y-libros/articulo/agregar',
              urlTo:
                '/administrativo/configuracion/materiales-y-libros/articulo/agregar'
            }
          }
        }
      }
    },
    infosys: {
      report: {
        general: {
          urlTo: '/infosys/reportes/general'
        },
        activosfijoslocalidad: {
          urlTo: '/infosys/reportes/activosfijoslocalidad'
        },
        activosfijosnaturaleza: {
          urlTo: '/infosys/reportes/activosfijosnaturaleza'
        },
        genero: {
          urlTo: '/infosys/reportes/genero'
        },
        centro: {
          urlTo: '/infosys/reportes/centro'
        },
        estudiante: {
          urlTo: '/infosys/reportes/estudiante'
        },
        evento: {
          urlTo: '/infosys/reportes/evento'
        },
        transferencia: {
          urlTo: '/infosys/reportes/transferencia'
        },
        retiro: {
          urlTo: '/infosys/reportes/retiro'
        },
        calificacion: {
          urlTo: '/infosys/reportes/calificacion'
        },
        calificacioncenter: {
          urlTo: '/infosys/reportes/calificacioncenter'
        },
        historialpromedioprofesor: {
          urlTo: '/infosys/reportes/historialpromedioprofesor'
        },
        historialpromedioestudiante: {
          urlTo: '/infosys/reportes/historialpromedioestudiante'
        },
        announcement: {
          urlTo: '/infosys/reportes/convocatoria'
        },
        centerInformation: {
          urlTo: '/infosys/reportes/informacion-de-centros'
        },
        cajachica: {
          urlTo: '/infosys/reportes/cajachica'
        },
        combustible: {
          urlTo: '/infosys/reportes/combustible'
        },
        correspondencia: {
          urlTo: '/infosys/reportes/correspondencia'
        },
        paselista: {
          urlTo: '/infosys/reportes/paselista'
        }
        
      },
      surveys: {
        survey: {
          urlTo: '/infosys/encuestas'
        },
        surveyAdd: {
          urlTo: '/infosys/encuestas/crear'
        },
        surveyDetail: {
          urlTo: '/infosys/encuestas/detalles/:id'
        },
        surveyEdit: {
          urlTo: '/infosys/encuestas/editar/:id'
        },
        surveyApplication: {
          urlTo: '/infosys/encuestas/aplicar'
        },
        surveyResults: {
          urlTo: '/infosys/encuestas/resultados'
        }
      },
      calendar : {
        academicCalendar: {
          urlTo: '/infosys/calendario-academico'
        },
        admissionsCalendar: {
          urlTo: '/infosys/calendario-admisiones'
        },
      },
      agenda : {
        electronicAgenda: {
          urlTo: '/infosys/agenda-electronica'
        },
      }
    },
    cash: {
      url: '/caja',
      urlTo: '/caja',
      expenses: {
        add: {
          url: '/caja/egresos/agregar',
          urlTo: '/caja/egresos/agregar'
        },
        list: {
          url: '/caja/egresos',
          urlTo: '/caja/egresos'
        }
      },
      incomes: {
        add: {
          url: '/caja/ingresos/agregar',
          urlTo: '/caja/ingresos/agregar'
        },
        list: {
          url: '/caja/ingresos',
          urlTo: '/caja/ingresos'
        }
      },
      reposition: {
        add: {
          url: '/caja/reposicion/agregar',
          urlTo: '/caja/reposicion/agregar'
        },
        list: {
          url: '/caja/reposicion',
          urlTo: '/caja/reposicion'
        }
      }
    },
    invoices: {
      url: '/facturas',
      urlTo: '/facturas',

      add: {
        url: '/facturas/agregar',
        urlTo: '/facturas/agregar'
      },
      detail: {
        url: '/facturas/detalle',
        urlTo: '/facturas/detalle'
      }
    },
    proofStudyLetter:{
      url: '/carta-constancia',
      urlTo: '/carta-constancia'
    },
    certificate:{
      url: '/certificado',
      urlTo: '/certificado',
      add: {
        url: '/certificado/agregar',
        urlTo: '/certificado/agregar'
      },
      edit: {
        url: '/certificado/editar/:id',
        urlTo: '/certificado/editar/:id'
      }
    },
    contracts: {
      url: '/contratos',
      urlTo: '/contratos',

      add: {
        url: '/contratos/agregar',
        urlTo: '/contratos/agregar'
      },
      detail: {
        url: '/contratos/detalle',
        urlTo: '/contratos/detalle'
      }
    },
    config: {
      manual: {
        applicant: {
          url: '/configuracion/manual/aplicante',
          urlTo: '/configuracion/manual/aplicante'
        },
        coordinator: {
          url: '/configuracion/manual/coordinador',
          urlTo: '/configuracion/manual/coordinador'
        },
        teacher: {
          url: '/configuracion/manual/profesor',
          urlTo: '/configuracion/manual/profesor'
        }
      },
      mainCover: {
        urlTo: '/configuracion/portada-principal'
      },
      reportHeader: {
        urlTo: '/configuracion/etiqueta-reporte'
      }
    }
  }
}
