import { lazy } from 'react'
import { urlRoutes } from 'ui/constant/urls'
import type { RouteType } from 'core/util/httpClient/domain/types/common'

export const infosysRoutes: RouteType[] = [
  {
    permissions: [
      'general.create.permission',
      'general.edit.permission',
      'general.delete.permission',
      'general.read.permission'
    ],
    path: urlRoutes.management.infosys.report.general.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'general',
      reportDisplayName:'General de Registro',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'institution',pType:'shape',required:true },
        { name:'province',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'status',pType:'shape',required:true },
        { name:'minAge',pType:'number',required:false },
        { name:'maxAge',pType:'number',required:false },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'general_por_estado,_provincia,_centro_y_genero.create.permission',
      'general_por_estado,_provincia,_centro_y_genero.edit.permission',
      'general_por_estado,_provincia,_centro_y_genero.delete.permission',
      'general_por_estado,_provincia,_centro_y_genero.read.permission'
    ],
    path: urlRoutes.management.infosys.report.genero.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'genero',
      reportDisplayName:'General de Registro Por Estado, Provincia y Genero',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'general_por_centro.create.permission',
      'general_por_centro.edit.permission',
      'general_por_centro.delete.permission',
      'general_por_centro.read.permission'
    ],
    path: urlRoutes.management.infosys.report.centro.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'centro',
      reportDisplayName:'General por Centro',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'eventos_de_capacitacion.create.permission',
      'eventos_de_capacitacion.edit.permission',
      'eventos_de_capacitacion.delete.permission',
      'eventos_de_capacitacion.read.permission'
    ],
    path: urlRoutes.management.infosys.report.evento.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'evento',
      reportDisplayName:'Eventos de Capacitación',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'institution',pType:'shape',required:true },
        { name:'activity',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'transferencias.create.permission',
      'transferencias.edit.permission',
      'transferencias.delete.permission',
      'transferencias.read.permission'
    ],
    path: urlRoutes.management.infosys.report.transferencia.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'transferencia',
      reportDisplayName:'Transferencias',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'level',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'retiros.create.permission',
      'retiros.edit.permission',
      'retiros.delete.permission',
      'retiros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.retiro.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'retiro',
      reportDisplayName:'Retiros',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'level',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'calificación_de_estudiantes_por_centro.create.permission',
      'calificación_de_estudiantes_por_centro.edit.permission',
      'calificación_de_estudiantes_por_centro.delete.permission',
      'calificación_de_estudiantes_por_centro.read.permission'
    ],
    path: urlRoutes.management.infosys.report.estudiante.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'estudiante',
      reportDisplayName:'Cantidad de Estudiantes por Centro',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'calificacion_de_estudiantes_por_centro.create.permission',
      'calificacion_de_estudiantes_por_centro.edit.permission',
      'calificacion_de_estudiantes_por_centro.delete.permission',
      'calificacion_de_estudiantes_por_centro.read.permission'
    ],
    path: urlRoutes.management.infosys.report.calificacion.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'calificacion',
      reportDisplayName:'Calificaciones de Estudiantes',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'level',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'historial_de_promedio_por_centro.create.permission',
      'historial_de_promedio_por_centro.edit.permission',
      'historial_de_promedio_por_centro.delete.permission',
      'historial_de_promedio_por_centro.read.permission'
    ],
    path: urlRoutes.management.infosys.report.historialpromedioprofesor.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'historialpromedioprofesor',
      reportDisplayName:'Historial de Promedio por Centro, Tanda y Profesores',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'historial_general_de_promedio_de_estudiantes.create.permission',
      'historial_general_de_promedio_de_estudiantes.edit.permission',
      'historial_general_de_promedio_de_estudiantes.delete.permission',
      'historial_general_de_promedio_de_estudiantes.read.permission'
    ],
    path: urlRoutes.management.infosys.report.historialpromedioestudiante.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'historialpromedioestudiante',
      reportDisplayName:'Historial General de Promedio por Estudiantes',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'calificaciones_promedio_por_centro,_tanda_y_aula.create.permission',
      'calificaciones_promedio_por_centro,_tanda_y_aula.edit.permission',
      'calificaciones_promedio_por_centro,_tanda_y_aula.delete.permission',
      'calificaciones_promedio_por_centro,_tanda_y_aula.read.permission'
    ],
    path: urlRoutes.management.infosys.report.calificacioncenter.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'calificacioncenter',
      reportDisplayName:'Calificaciones promedio por Centro, Tanda y Aula',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:false },
        { name:'province',pType:'shape',required:true },
        { name:'modality',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'gender',pType:'shape',required:true },
        { name:'level',pType:'shape',required:true },
        { name:'status',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'reporte_caja_chica.create.permission',
      'reporte_caja_chica.edit.permission',
      'reporte_caja_chica.delete.permission',
      'reporte_caja_chica.read.permission'
    ],
    path: urlRoutes.management.infosys.report.cajachica.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'cajachica',
      reportDisplayName:'Caja Chica',
      schema:[
        { name:'registertype',pType:'shape',required:true },
        { name:'initialdate',pType:'date',required:true },
        { name:'enddate',pType:'date',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'reporte_combustible.create.permission',
      'reporte_combustible.edit.permission',
      'reporte_combustible.delete.permission',
      'reporte_combustible.read.permission'
    ],
    path: urlRoutes.management.infosys.report.combustible.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'combustible',
      reportDisplayName:'Combustible',
      schema:[
        { name:'initialdate',pType:'date',required:true },
        { name:'enddate',pType:'date',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'correspondencia.create.permission',
      'correspondencia.edit.permission',
      'correspondencia.delete.permission',
      'correspondencia.read.permission'
    ],
    path: urlRoutes.management.infosys.report.correspondencia.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'correspondencia',
      reportDisplayName:'Correspondencia',
      schema:[
        { name:'correspondencetype',pType:'shape',required:true },
        { name:'initialdate',pType:'date',required:true },
        { name:'enddate',pType:'date',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'pase_de_lista_de_nivel_/_admisiones.create.permission',
      'pase_de_lista_de_nivel_/_admisiones.edit.permission',
      'pase_de_lista_de_nivel_/_admisiones.delete.permission',
      'pase_de_lista_de_nivel_/_admisiones.read.permission'
    ],
    path: urlRoutes.management.infosys.report.paselista.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'paselista',
      reportDisplayName:'Pase de Lista de Nivel / Admisiones',
      schema:[
        { name:'announcement',pType:'shape',required:true },
        { name:'region',pType:'shape',required:true },
        { name:'province',pType:'shape',required:true },
        { name:'institution',pType:'shape',required:true },
        { name:'center',pType:'shape',required:true },
        { name:'classroom',pType:'shape',required:true },
        { name:'tanda',pType:'shape',required:true },
        { name:'level',pType:'shape',required:true },
        { name:'attendance',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'activos_fijos_por_localidad.create.permission',
      'activos_fijos_por_localidad.edit.permission',
      'activos_fijos_por_localidad.delete.permission',
      'activos_fijos_por_localidad.read.permission'
    ],
    path: urlRoutes.management.infosys.report.activosfijoslocalidad.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'activosfijoslocalidad',
      reportDisplayName:'Activos fijos por Localidad / Administrativo',
      schema:[
        { name:'locality',pType:'shape',required:true },
        { name:'nature',pType:'shape',required:true },
        { name:'typology',pType:'shape',required:true },
        { name:'assetscondition',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'activos_fijos_por_naturaleza.create.permission',
      'activos_fijos_por_naturaleza.edit.permission',
      'activos_fijos_por_naturaleza.delete.permission',
      'activos_fijos_por_naturaleza.read.permission'
    ],
    path: urlRoutes.management.infosys.report.activosfijosnaturaleza.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/shared/components/common/ReportingForm/ReportingForm'
        )
    ),
    extra:{
      reportName:'activosfijosnaturaleza',
      reportDisplayName:'Activos fijos por Naturaleza / Administrativo',
      schema:[
        { name:'locality',pType:'shape',required:true },
        { name:'nature',pType:'shape',required:true },
        { name:'typology',pType:'shape',required:true },
        { name:'assetscondition',pType:'shape',required:true },
        { name:'format',pType:'shape',required:true },
      ]
    }
  },
  {
    permissions: [
      'información_de_centros.create.permission',
      'información_de_centros.edit.permission',
      'información_de_centros.delete.permission',
      'información_de_centros.read.permission'
    ],
    path: urlRoutes.management.infosys.report.centerInformation.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/report/centerInformation/reportCenterInfo'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.survey.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/survey/infosysSurvey'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.surveyApplication.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/surveyApplication/surveyApplication'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.surveyAdd.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/survey/create/infosysSurveyCreate'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.surveyDetail.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/survey/detail/infosysSurveyDetail'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.surveyEdit.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/survey/edit/infosysSurveyEdit'
        )
    )
  },
  {
    permissions: [
      'encuestas.create.permission',
      'encuestas.edit.permission',
      'encuestas.delete.permission',
      'encuestas.read.permission'
    ],
    path: urlRoutes.management.infosys.surveys.surveyResults.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/surveys/surveyAnswers/surveyAnswers'
        )
    )
  },
  {
    permissions: [
      'calendario_academico.create.permission',
      'calendario_academico.edit.permission',
      'calendario_academico.delete.permission',
      'calendario_academico.read.permission'
    ],
    path: urlRoutes.management.infosys.calendar.academicCalendar.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/calendar/academicCalendar/academicCalendar'
        )
    )
  },
  {
    permissions: [
      'calendario_admisiones.create.permission',
      'calendario_admisiones.edit.permission',
      'calendario_admisiones.delete.permission',
      'calendario_admisiones.read.permission'
    ],
    path: urlRoutes.management.infosys.calendar.admissionsCalendar.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/calendar/admissionsCalendar/admissionsCalendar'
        )
    )
  },
  {
    permissions: [
      'agenda_electronica.create.permission',
      'agenda_electronica.edit.permission',
      'agenda_electronica.delete.permission',
      'agenda_electronica.read.permission'
    ],
    path: urlRoutes.management.infosys.agenda.electronicAgenda.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/electronicAgenda/electronicAgenda'
        )
    )
  },
  {
    permissions: [
      'convocatoria_infosys.create.permission',
      'convocatoria_infosys.edit.permission',
      'convocatoria_infosys.delete.permission',
      'convocatoria_infosys.read.permission'
    ],
    path: urlRoutes.management.infosys.report.announcement.urlTo,
    element: lazy(
      async () =>
        await import(
          'ui/modules/management/infosys/report/announcement/reportAnnouncement'
        )
    )
  },
]
